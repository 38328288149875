
export default class StatusesEnum {
    static active = {
        name: 'active',
        value: 'active',
        color_text: 'green_color_text'
    };

    static cancelled = {
        name: 'cancelled',
        value: 'cancelled',
        color_text: 'red_color_text'
    };

    static non_renewing = {
        name: 'non renewing',
        value: 'non_renewing',
        color_text: 'orange_color_text'
    };
}
