<template>
  <div class="w-full">
    <div class="flex justify-between">
      <div class="w-1/3 text-left py-4 section_name">
        Billing
      </div>
    </div>

    <div class="flex justify-between mb-4" v-if="plan">
      <div class="w-full flex justify-between py-2 border_bottom">
        <div class="sub_section_name strong_grey_text_color">
          Plan details
        </div>

        <div
            @click="openCanceledModal"
            class="sub_section_name green_color_text pointer_div">
          Cancel Subscription
        </div>
      </div>
    </div>

    <div class="flex justify-between mb-4" v-else>
      <div class="w-full flex justify-start py-2 border_bottom">
        <div class="sub_section_name strong_red_text_color">
          User does not have a plan subscription
        </div>
      </div>
    </div>

    <div class="w-full py-1 mb-4" v-if="plan">
      <div class="w-full flex py-1 justify-center items-center">
        <div class="w-1/4 px-2 text-right sub_sub_section_name grey_color_text">
          Plan
        </div>

        <div class="w-3/4 px-2 text-left sub_sub_section_name blue_color_text capitalize">
          {{plan.name}}
        </div>
      </div>

      <div class="w-full flex py-1  justify-center items-center">
        <div class="w-1/4 px-2 text-right sub_sub_section_name grey_color_text">
          Status
        </div>

        <div
            v-if="statuses_type"
            class="w-3/4 px-2 text-left sub_sub_section_name capitalize"
            :class="statuses_type.color_text">
          {{ statuses_type.name }}
        </div>
      </div>

      <div class="w-full flex py-1  justify-center items-center">
        <div class="w-1/4 px-2 text-right sub_sub_section_name grey_color_text">
          Plan description
        </div>

        <div class="w-3/4 capitalize px-2 text-left sub_sub_section_name capitalize">
          {{ plan.description }}
        </div>
      </div>

      <div class="w-full flex py-1  justify-center items-center">
        <div class="w-1/4 px-2 text-right sub_sub_section_name grey_color_text">
          Billing period
        </div>

        <div class="w-3/4 capitalize px-2 text-left sub_sub_section_name capitalize">
          {{ plan.billing_period }}
        </div>
      </div>

      <div class="w-full flex py-1  justify-center items-center">
        <div class="w-1/4 px-2 text-right sub_sub_section_name grey_color_text">
          Payment details
        </div>

        <div class="w-3/4 capitalize px-2 text-left sub_sub_section_name">
          <button @click="openBillingManageModal" class="blue_color_text border_bottom_blue">
            Manage
          </button>
          <span class="mx-2">|</span>
          <button @click="goToInvoices" class="blue_color_text border_bottom_blue">
            Invoices
          </button>
        </div>
      </div>

      <div class="w-full flex py-1  justify-center items-center">
        <div class="w-1/4 px-2 text-right sub_sub_section_name grey_color_text">
          Expires at
        </div>

        <div class="w-3/4 px-2 text-left sub_sub_section_name" v-if="subscription.end_date || false">
          {{ this.dataFormat(subscription.end_date) }}
        </div>
      </div>

      <div class="w-full flex py-1  justify-center items-center" v-if="statuses_type && statuses_type.name == 'active'">
        <div class="w-1/4 px-2 text-right sub_sub_section_name grey_color_text">
          Renewal Date
        </div>

        <div class="w-3/4 px-2 text-left sub_sub_section_name" v-if="subscription.end_date || false">
          {{ this.dataFormat(subscription.end_date) }} ( <span class="italics">Your next payment is due on this date</span> )
        </div>
      </div>
    </div>

    <div class="mb-4" v-if="statuses_type && statuses_type.value != 'cancelled'">
      <div class="w-full flex justify-between py-2 border_bottom">
        <div class="sub_section_name strong_grey_text_color">
          Subscription auto renewal
        </div>
      </div>

      <div class="w-full flex justify-start py-2">
        <div class="flex justify-start items-center py-2">
          <div class="ml-8">
            <div class="form-check form-switch px-2">
              <input
                  class="checked_input form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-gray-400 bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                  type="checkbox"
                  role="switch"
                  v-model="renewal"
                  @click="toggleStatusSubscription"
                  id="flexSwitchCheckDefault">
            </div>
          </div>

          <div class="px-2 sub_sub_section_name">
            {{renewal ? 'On' : 'Off'}}
          </div>
        </div>
      </div>

      <div class="w-full flex justify-between sub_sub_section_name">
        Your plan automatically renews on your renewal date
      </div>
    </div>

    <CancelSubscriptionModal
        v-on:close-subscription-modal="checkedCloseModal($event)"
        :external_id="subscription.external_subscription_id || ''"
        :period="period"
        :user="this.user"
        ref="openCanceledSubscriptionModal"></CancelSubscriptionModal>

    <BillingManageModal
        :card="card ? card.masked_number || '' : ''"
        ref="openBillingManageModal"></BillingManageModal>

  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import {infoMessage} from "../services/messages";
import StatusesEnum from "@/enums/StatusesEnum";
import CancelSubscriptionModal from "@/components/CancelSubscriptionModal";
import BillingManageModal from "@/components/BillingManageModal";

export default {
  name: "Billing",
  data() {
    return {
      user: null,
      plan: null,
      statuses_type: null,
      subscription: {},
      period: '',
      renewal: false,
      toggle_renewal: false,
      card: null,
      customer: null,
      subscription_item: null,
    }
  },
  methods: {
    ...mapMutations([
      'loginUser',
      'setLoading'
    ]),
    async checkedCloseModal(val) {
      this.setLoading(true);
      if (!val) {
        this.setLoading(false);
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      await this.getAllData();

      this.setLoading(false);
      infoMessage(
          'Subscription canceled!',
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
    },
    openBillingManageModal() {
      this.$refs.openBillingManageModal.show = true;
    },
    openCanceledModal() {
      this.$refs.openCanceledSubscriptionModal.show = true;
    },
    goToInvoices() {
      this.$router.push('/invoices');
    },
    dayFormat (val) {
      if (val < 10) {
        return '0' + val;
      }
      return val;
    },
    dataFormat(val) {
      let date = new Date(val);

      return this.dayFormat(date.getDate()) + '-' + this.dayFormat(date.getMonth() + 1) + '-' + date.getFullYear();
    },
    async getPlan(id) {
      const res = await this.$http.getAuth(`${this.$http.apiUrl()}/subscription-plans/${id}/plan`);

      if (res?.data?.err) {
        this.setLoading(false);
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      this.plan = res?.data?.plan || null;
    },
    async getUserWithRelations() {
      const result = await this.$http.getAuth(`${this.$http.apiUrl()}/users/with-subscriptions`);

      if (result?.data?.err) {
        this.setLoading(false);
        console.log(result?.data?.err);
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      this.user = result?.data?.user || null;

      if (this.user) {
        this.subscription = this.user?.user_subscription || {};
      }
    },

    async retriveSubscription() {
      const res = await this.$http.postAuth(
          `${this.$http.apiUrl()}/subscriptions/get-subscription-info`,
          {
            external_subscription_id: this.subscription.external_subscription_id,
          })
          .catch(err => {
            console.log(err);
            this.setLoading(false);
            return infoMessage(
                'Oops. Something went wrong...',
                this.getTopBanner ? 'error open_top_banner' :'error'
            );
          });

      this.card = res?.data?.result?.card || null;
      this.customer = res?.data?.result?.customer || null;
      this.subscription_item = res?.data?.result?.subscription || null;
    },
    async toggleStatusSubscription() {
      this.setLoading(true);
      this.toggle_renewal = !this.toggle_renewal;
      let data = {
        external_subscription_id: this.subscription.external_subscription_id,
        renewal: this.toggle_renewal,
        user_subscription_id: this.subscription.id,
      };

      let res_update = await this.$http.postAuth(`${this.$http.apiUrl()}/subscriptions/update-auto-refresh`, data);

      if (res_update?.data?.err) {
        this.setLoading(false);
        infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      infoMessage(
          `Subscription auto renewal updated`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );

      await this.getAllData();
      this.setLoading(false);
    },
    async getAllData() {
      this.setLoading(true);
      await this.getUserWithRelations();
      if (this.user && this.user.user_subscription && this.user.user_subscription.subscription_plan_id) {
        await this.getPlan(this.user.user_subscription.subscription_plan_id);
      } else {
        this.setLoading(false);
        return infoMessage(
            'User does not have a plan subscription',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      let status_result = this.user?.user_subscription?.status || null;
      if (status_result) {
        this.statuses_type = StatusesEnum[status_result];
      }

      if (this.subscription) {
        this.period = this.dataFormat(this.subscription?.end_date || new Date());
      }

      await this.retriveSubscription();
      if (this.subscription_item.status === StatusesEnum.active.value) {
        this.renewal = true;
      } else {
        this.renewal = false;
      }

      this.toggle_renewal = !this.renewal;
      this.setLoading(false);
    }
  },
  async mounted() {
    await this.getAllData();
  },
  computed: {
    ...mapGetters([
      'getTopBanner'
    ]),
  },
  components: {
    CancelSubscriptionModal,
    BillingManageModal
  },
  watch: {}
}
</script>
<style lang="scss" scoped>
.section_name {
  color: #22292f;
  font-size: 1.17em;
  font-weight: 700;
}
.sub_section_name {
  //font-size: 1.1em;
  font-size: 14px;
  font-weight: 600;
}
.sub_sub_section_name {
  //font-size: 1.1em;
  font-size: 14px;
  font-weight: 500;
}
.grey_color_text {
  color: #606f7b;
}
.green_color_text {
  color: #1fbc2f;
}
.red_color_text {
  color: #F2374D;
}
.orange_color_text {
  color: #ffa800;
}
.blue_color_text {
  color: #0088cc;
}
.strong_grey_text_color {
  color: #666666;
}
.strong_red_text_color {
  color: #F2374D;
}
.pointer_div {
  cursor: pointer;
}
.border_bottom {
  border-bottom: 1px solid #dae1e7;
}
.border_bottom_blue {
  border-bottom: 1px solid #0088cc !important;
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 64px;
}
.italics {
  font-style: italic !important;
}
.checked_input {
  width: 60px;
  height: 30px;
}
.form-check-input:checked {
  background-color: #28a745;
  border-color: #28a745;
}
</style>