<template>
  <div class="modal-shadow saved_popup min-h-screen flex flex-col" v-if="show" @click.self="closeModal">
    <div class="inner">
      <div class="content">
        <div class="w-full flex justify-start modal_header p-6 title_line">
          <div class="icon_block flex items-center">
            <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.102539 6.89858C0.102539 3.27512 3.05051 0.327148 6.67397 0.327148C10.2975 0.327148 13.2454 3.27512 13.2454 6.89858C13.2454 11.2289 6.68043 19.2326 6.68043 19.2326C6.68043 19.2326 0.102539 11.4529 0.102539 6.89858ZM6.67397 9.64265C7.39204 9.64265 8.10999 9.3693 8.6567 8.82269C9.75002 7.7295 9.75002 5.95065 8.6567 4.85734C8.12707 4.32782 7.42296 4.036 6.67397 4.036C5.92498 4.036 5.22075 4.3277 4.69135 4.85734C3.59803 5.95065 3.59803 7.7295 4.69135 8.82269C5.23783 9.3693 5.95602 9.64265 6.67397 9.64265ZM9.62584 17.47H17.5311H21.8169H23.2454C23.2454 18.7324 24.2687 19.7557 25.5311 19.7557C26.7935 19.7557 27.8168 18.7324 27.8168 17.47H30.674C31.1474 17.47 31.5311 17.0862 31.5311 16.6129V11.777C31.5311 11.1606 31.3317 10.5607 30.9627 10.0668L27.1882 5.01531C26.8646 4.58221 26.3556 4.32715 25.8149 4.32715H21.8169V2.61286C21.8169 1.3505 20.7935 0.327148 19.5312 0.327148H10.9779C13.1167 1.73235 14.5312 4.1529 14.5312 6.89858C14.5312 8.25914 14.029 9.7736 13.3909 11.1829C12.7395 12.6216 11.8824 14.0868 11.0431 15.3868C10.5564 16.1407 10.0705 16.847 9.62584 17.47ZM25.5286 6.61286H22.674V10.6129H27.8168V9.67526L25.5286 6.61286Z" fill="#1FBC2F"/>
            </svg>
          </div>

          <div class="modal_title pb-2 pt-3">
            Help us improve!
          </div>
        </div>

        <div class="modal_body flex flex-col content-center">
          <div class="w-full px-6">
            <div class="text-left mb-4">
              As a valued customer, we would love to hear you reason for canceling your subscription
            </div>
            
            <div
                class="flex justify-start items-center py-2"
                v-for="(item, index) in radio"
                :key="'radio-reason-' + index + '-' + item.id">
              <div class="pr-2 flex items-center">
                <input
                    @click="skipErrors"
                    name="radioCheck"
                    type="radio"
                    :value="item.value"
                    v-model="radioCheck">
              </div>

              <div class="px-2 flex items-center">
                {{item.description}}
              </div>

              <div class="px-2 flex items-center" v-if="item.input">
                <div class="relative">
                  <input @input="skipErrors"
                         class="border w-full"
                         type="text"
                         v-model="other">
                  <p v-if="errors.other_exists" class="error abs">
                    This field is required
                  </p>
                </div>

              </div>
            </div>

            <div class="text-left py-2 mt-4">
              Your subscription will end at the end of your current billing period on {{period}}.
            </div>

            <div class="text-left mb-4">
              You can continue to use Lorry Route until the end of your billing period.
            </div>
          </div>
        </div>

        <div v-if="errors.no_checked" class="text-left error px-6">
          Choose one of the items
        </div>

        <div class="btn_line flex justify-between w-full px-6 modal_footer">
          <div class="border-t border-gray-200 py-6 flex w-full">
            <div class="w-1/2 justify-center">
              <button
                  @click="confirmed"
                  class="this_button py-3 px-3 border text-red-500 bg-gray-200 border-gray-300 hover:text-white hover:bg-red-500 hover:border-red-500">
                Confirm cancellation
              </button>
            </div>

            <div class="w-1/2 justify-center">
              <button
                  @click="closeModal"
                  class="this_button mr-3 py-3 px-3 border text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
export default {
  name: "CanceledSubscriptionModal",
  props: {
    period: {
      type: String,
      default: '',
    },
    external_id: {
      type: String,
      default: '',
    },
    user: {
      default: null,
    }
  },
  data: function () {
    return {
      radioCheck: null,
      show: false,
      // user: null,
      errors: {
        other_exists: false,
        no_checked: false,
      },
      other: null,
      radio: [
        {
          id: 1,
          description: 'Don`t need it at the moment',
          input: false,
          value: 1,
        },
        {
          id: 2,
          description: 'Going to use a different routing service',
          input: false,
          value: 2,
        },
        {
          id: 3,
          description: 'Don`t own/manage vehicles anymore',
          input: false,
          value: 3,
        },
        {
          id: 4,
          description: 'Change of business circumstances',
          input: false,
          value: 4,
        },
        {
          id: 5,
          description: 'Had problems using Lorry Route',
          input: false,
          value: 5,
        },
        {
          id: 6,
          description: 'Too expensive',
          input: false,
          value: 6,
        },
        {
          id: 7,
          description: 'Other',
          input: true,
          value: 7,
        }
      ],
    }
  },
  methods: {
    ...mapMutations([
      'setLoading'
    ]),
    async returnDescription() {
      if (this.radioCheck) {
        if (this.radioCheck === 7 && this.other) {
          return this.other;
        }
        for (let key in this.radio) {
          if (this.radio[key].value === this.radioCheck) {
            return this.radio[key].description;
          }
        }
      }
      return null;
    },
    async validationForm() {
      if (this.radioCheck) {
        if (this.radioCheck === 7) {
          if (!this.other || this.other.length < 3) {
            this.errors.other_exists = true;
            return false;
          }
          return true;
        }
        this.other = null;
        this.errors.other_exists = false;
        return true;
      }

      this.errors.no_checked = true;
    },
    async closeModal() {
      this.other = null;
      this.radioCheck = null;
      await this.skipErrors();
      this.show = false
    },
    async getUser() {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
    },
    async skipErrors() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }
    },
    async confirmed() {
      this.setLoading(true);
      await this.skipErrors();

      if (await this.validationForm()) {
        const result = await this.$http.postAuth(
            `${this.$http.apiUrl()}/subscriptions/cancel-subscription`,
            {
              external_subscription_id: this.external_id,
              user_id: this.user.id,
              response_code: await this.returnDescription(),
            }
        );

        if (result?.data?.err) {
          this.$emit('close-subscription-modal', false);
        } else {
          this.$emit('close-subscription-modal', true);
        }

        await this.closeModal();
      }
      this.setLoading(false);
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.39);
  z-index: 402;
}
.modal_header {
  border-radius: 8px 8px 0 0;
  border-bottom: none;
}
.modal_body {
  min-height: 100px;
}
.saved_popup {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  @media screen and (max-width: 800px) {
    .inner {
      min-width: 330px;
    }
  }
}
.inner {
  max-width: 546px;
  width: 50vw;
  margin: auto;
  min-height: 233px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.inner .content {
  align-items: center;
  gap: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.inner .btn {
  margin: 0 20px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.btn_line {
  border-radius: 0 0 8px 8px;
}
.input_label {
  font-weight: normal;
  font-size: 16px;
  span {
    color: #28a745;
  }
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
}
.bg_green {
  background-color: #1fbc2f;
}
.icon_block {
  width: 50px;
  svg {
    width: 100%;
  }
}
.abs {
  position: absolute;
  top: 24px;
  left: 0;
}
</style>
