<template>
  <div class="modal-shadow saved_popup min-h-screen flex flex-col" v-if="show" @click.self="closeModal">
    <div class="inner">
      <div class="content">
        <div class="modal_header p-6 title_line">
          <div class="w-full flex justify-center">
            <div class="icon_block flex items-center">
              <svg viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.102539 6.89858C0.102539 3.27512 3.05051 0.327148 6.67397 0.327148C10.2975 0.327148 13.2454 3.27512 13.2454 6.89858C13.2454 11.2289 6.68043 19.2326 6.68043 19.2326C6.68043 19.2326 0.102539 11.4529 0.102539 6.89858ZM6.67397 9.64265C7.39204 9.64265 8.10999 9.3693 8.6567 8.82269C9.75002 7.7295 9.75002 5.95065 8.6567 4.85734C8.12707 4.32782 7.42296 4.036 6.67397 4.036C5.92498 4.036 5.22075 4.3277 4.69135 4.85734C3.59803 5.95065 3.59803 7.7295 4.69135 8.82269C5.23783 9.3693 5.95602 9.64265 6.67397 9.64265ZM9.62584 17.47H17.5311H21.8169H23.2454C23.2454 18.7324 24.2687 19.7557 25.5311 19.7557C26.7935 19.7557 27.8168 18.7324 27.8168 17.47H30.674C31.1474 17.47 31.5311 17.0862 31.5311 16.6129V11.777C31.5311 11.1606 31.3317 10.5607 30.9627 10.0668L27.1882 5.01531C26.8646 4.58221 26.3556 4.32715 25.8149 4.32715H21.8169V2.61286C21.8169 1.3505 20.7935 0.327148 19.5312 0.327148H10.9779C13.1167 1.73235 14.5312 4.1529 14.5312 6.89858C14.5312 8.25914 14.029 9.7736 13.3909 11.1829C12.7395 12.6216 11.8824 14.0868 11.0431 15.3868C10.5564 16.1407 10.0705 16.847 9.62584 17.47ZM25.5286 6.61286H22.674V10.6129H27.8168V9.67526L25.5286 6.61286Z" fill="#1FBC2F"/>
              </svg>
            </div>

            <div class="modal_title px-2 pt-3 text_green">
              <div class="text-left big_text">
                Lorry <span class="no_strong_text">Route</span>
              </div>
              <div class="text-left uppercase small_text no_strong_text">
                Compliant routing made simple
              </div>
            </div>
          </div>

          <div class="w-full flex justify-center text_black capitalize">
            Payment Method Details
          </div>

          <div class="end_button">
            <button class="x_btn" @click="closeModal">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </button>
          </div>
        </div>

        <div class="modal_body flex flex-col content-center">
          <div class="w-full flex justify-center">
            <div class="icon_block">
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                   width="93.000000pt" height="61.000000pt" viewBox="0 0 93.000000 61.000000"
                   preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,61.000000) scale(0.100000,-0.100000)"
                   fill="#000000" stroke="none">
                  <path d="M237 546 c-127 -46 -198 -192 -152 -312 23 -60 78 -122 130 -145 50-23 141 -24 195 -3 22 9 40 18 40 21 0 3 -15 22 -33 44 -79 92 -76 237 6 335 30 35 26 43 -32 63 -45 15 -107 13 -154 -3z"/>
                  <path d="M545 548 c-54 -19 -58 -27 -28 -62 41 -48 63 -106 63 -165 0 -73 -17 -124 -57 -170 -18 -22 -33 -41 -33 -44 0 -14 88 -37 138 -37 71 0 126 25 173 77 75 83 89 195 34 288 -58 100 -186 150 -290 113z"/>
                  <path d="M434 468 c-39 -47 -54 -90 -54 -155 0 -64 31 -144 69 -177 21 -19 21 -19 42 0 59 52 88 190 56 267 -15 35 -64 97 -77 97 -5 0 -21 -15 -36 -32z"/>
                </g>
              </svg>
            </div>
          </div>

          <div class="w-full flex justify-center text_medium">
            ending {{card}}
          </div>

          <div class="w-full flex justify-start capitalize blue_text text_medium my-8 px-8">
            Edit payment method
          </div>
        </div>

        <div class="btn_line flex justify-between w-full px-6 modal_footer">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BillingManageModal",
  props: {
    card: {
      type: String,
      default: '',
    }
  },
  data: function () {
    return {
      show: false,
      user: null,
      button_disable: true,
      errors: {
        name_exists: false,
        name_required: false,
      },
      name: null,
    }
  },
  methods: {
    toggleDisableButton() {
      if (this.name.length) {
        this.button_disable = false;
      } else {
        this.button_disable = true;
      }
    },
    async closeModal() {
      this.name = null;
      await this.skipErrors();
      this.show = false
    },
    async getUser() {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
    },
    async skipErrors() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }
    },
    async confirmed() {
      await this.skipErrors();
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.39);
  z-index: 402;
}
.modal_header {
  position: relative;
  border-radius: 8px 8px 0 0;
  border-bottom: none;
}
.modal_body {
  min-height: 100px;
}
.saved_popup {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  @media screen and (max-width: 800px) {
    .inner {
      min-width: 330px;
    }
  }
}
.inner {
  max-width: 546px;
  width: 50vw;
  margin: auto;
  min-height: 233px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.inner .content {
  align-items: center;
  gap: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.inner .btn {
  margin: 0 20px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.btn_line {
  border-radius: 0 0 8px 8px;
}
.input_label {
  font-weight: normal;
  font-size: 16px;
  span {
    color: #28a745;
  }
}
.text_green {
  color: #28a745;
}
.text_black {
  color: black;
}
.blue_text {
  color: #0088cc;
}
.no_strong_text {
  font-weight: 400;
}
.big_text {
  font-size: 2em;
}
.small_text {
  font-size: 0.7em;
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
}
.text_medium {
  font-weight: 500;
  font-size: 1.17em;
  line-height: 24px;
}
.bg_green {
  background-color: #1fbc2f;
}
.icon_block {
  width: 50px;
  svg {
    width: 100% !important;
  }
}
.end_button {
  position: absolute;
  top: 15px;
  right: 0;
}
</style>
